import React from 'react'

class NotFound extends React.Component {
  render() {


    return (
      <>
        <h1 style={{marginTop: 200, marginBottom: 100}}>Page introuvable</h1>
      </>
    )
  }
}

export default NotFound
